.button {
    background-color: mediumseagreen;
    border-radius: 8px;
    display: inline-block;
    cursor: pointer;
    font-size: 20px;
    font-weight: bold;
    padding: 14px 32px;
    margin: 0px 20px 0px 20px;
}

.button:hover {
    background-color: #306b30;
}

.button:active {
    position: relative;
    top: 1px;
}

@media screen and (max-width: 600px) {
    .button {
        font-size: 18px;
        margin: 0px 10px 0px 10px;
    }
}

@media screen and (max-width: 409px) {
    .button {
        font-size: 17px;
        margin: 0px 6px 0px 6px;
    }
}

@media screen and (max-width: 367px) {
    .button {
        font-size: 15px;
        margin: 0px 6px 0px 6px;
        padding: 14px 27px;
    }
}