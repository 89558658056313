.username {
    font-style: italic;
}

.headline {
    color: black;
}

.headline > h1 > span {
    white-space: nowrap;
}