.save-button {
    background-color: mediumseagreen;
    border-radius: 4px;
    display: inline-block;
    cursor: pointer;
    font-size: 20px;
    font-weight: bold;
    padding: 8.9px 14px;
    margin: 10px 7px 0 7px;
}

.save-button:hover {
    background-color: #306b30;
}

.save-button:active {
    position: relative;
    top: 1px;
}

@media screen and (max-width: 1024px) {
    .save-button {
        padding: 9px 14px;
        margin: 7px;
    }
}