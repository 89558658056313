.popularity-content {
    font-size: 20px;
    margin-bottom: 10px;
    display: inline-flex;
    gap: 10px;
    color: black;
}

.popularity-link {
    color: black;
    text-decoration: none;
    target-new: tab;
}

.name, .average-popularity {
    font-size: 16px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

.popularity-element {
    padding: 10px 20px 10px 20px;
    max-width: 163px;
    border: 1px solid #e3e3e3;
    border-radius: 3px;
    min-width: 163px;
    flex-basis: 100%;
}

.description {
    font-weight: bold;
}

@media screen and (max-width: 600px) {
    .popularity-element {
        max-width: 134px;
        min-width: 134px;
    }

    .popularity-content {
        font-size: 18px;
    }
}

@media screen and (max-width: 409px) {
    .popularity-element {
        max-width: 113px;
        min-width: 113px;
    }
}

@media screen and (max-width: 367px) {
    .popularity-content, .name, .average-popularity {
        font-size: 15px;
    }

    .popularity-element {
        max-width: 89px;
        min-width: 89px;
    }
}

