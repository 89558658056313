.audio-feature {
    padding: 10px 20px 10px 20px;
    max-width: 163px;
    border: 1px solid #e3e3e3;
    border-radius: 3px;
    min-width: 106px;
    flex-basis: 100%;
    color: black;
}
.audio-feature-category, .audio-feature-emoji {
    font-weight: bold;
    font-size: 20px;
}

@media screen and (max-width: 600px) {
    .audio-feature {
        max-width: 134px;
        min-width: 113px;
    }

    .audio-feature-category, .audio-feature-emoji {
        font-size: 18px;
    }
}

@media screen and (max-width: 409px) {
    .audio-feature {
        max-width: 113px;
        min-width: 113px;
    }
}

@media screen and (max-width: 367px) {
    .audio-feature {
        max-width: 89px;
        min-width: 89px;
        font-size: 15px;
    }

    .audio-feature-category, .audio-feature-emoji {
        font-size: 15px;
    }
}
