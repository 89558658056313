.term-button {
    padding: 10px;
    background-color: rgba(166, 166, 166, 1);
    border-radius: 8px;
    font-size: 20px;
    font-weight: bold;
    cursor: pointer;
    border: none;
    margin: 8px;
}

.term-button:hover {
    border: none;
    background: rgba(89, 89, 89, 1);
    box-shadow: 0px 0px 1px #777;
}

@media screen and (max-width: 600px) {
    .term-button {
        font-size: 18px;
        margin: 4px;
    }
}

@media screen and (max-width: 367px) {
    .term-button {
        font-size: 15px;
        margin: 4px;
    }
}


