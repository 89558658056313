.show-all-button {
    background-color: rgba(166, 166, 166, 1);
    border-radius: 8px;
    display: inline-block;
    cursor: pointer;
    font-size: 20px;
    font-weight: bold;
    padding: 15px;
    margin: 20px;
    width: 450px;
}

.show-all-button:hover {
    background-color: rgb(89, 89, 89);
}

.show-all-button:active {
    position: relative;
    top: 1px;
}

@media screen and (max-width: 600px) {
    .show-all-button {
        width: 300px;
    }
}

@media screen and (max-width: 409px) {
    .show-all-button {
        width: 268px;
    }
}

@media screen and (max-width: 367px) {
    .show-all-button {
        width: 220px;
    }
}