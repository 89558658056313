.login-button > button {
    border-radius: 8px;
    font-size: 20px;
    font-weight: bold;
    padding: 14px 32px;
    margin: 10px 20px 10px 20px;
    color: white;
    border: 0;
}

.login-button > button:hover {
    background-color: #306b30;
}

.login-button > button:active {
    position: relative;
    top: 1px;
}

.login-headline {
    font-size: 32px;
    font-weight: bold;
}

.login-sub-headline {
    font-size: 20px;
    margin-bottom: 12px;
    margin-top: 4px;
}
